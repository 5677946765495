import client from '../apollo/client';
import complainHereMutation from '../queries/complainHere';

export const complainHere = variables => {
  return client.mutate({
    variables,
    mutation: complainHereMutation,
  });
};

export default {
  complainHere,
};
