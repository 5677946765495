import gql from 'graphql-tag';

export default gql`
  mutation complainHere(
    $ticket: String
    $title: String!
    $email: String!
    $message: String!
  ) {
    complainHere(
      ticket: $ticket
      title: $title
      email: $email
      message: $message
    )
  }
`;
