import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { Spinner } from 'reactstrap';
import Yup from '../../utils/yup';
import SEO from '../../components/Seo';
import EmailField from '../../components/EmailField';
import TextField from '../../components/TextField';
import { complainHere } from '../../api/complainHere';
import useAlert, { ALERT_TYPES } from '../../hooks/useAlert';

const Article = styled.article`
  margin: 0 auto;
  max-width: 760px;
  padding: 2rem 1rem;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 715px;
  padding: 1rem 0;
`;

const ReclameAqui = styled.h1`
  color: #90b823;
  font-family: 'Impact', 'Arial Black', 'Open Sans', Arial, sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 28px;
  span {
    display: none;
  }
  &:before {
    content: 'Reclame';
  }
  &:after {
    content: 'AQUI';
    color: #007535;
  }
`;

const CentralDoFrete = styled.h2`
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
`;

const P = styled.p`
  color: #848684;
  font-size: 16px;
  text-align: center;
  margin: 2rem 0 3rem;
`;

const Subtitle = styled.h2`
  font-family: 'Open Sans', Arial, sans-serif;
  color: #838383;
  font-size: 23px;
  font-weight: normal;
  margin: 1.5rem 0;
  text-align: center;
`;

const FormWrapper = styled.div`
  margin: 0 0 2rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 2rem;
  input {
    height: 48px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    &.form-control.is-invalid,
    &.was-validated .form-control:invalid,
    &.form-control.is-valid,
    &.was-validated .form-control:valid {
      background-position: 98.6% calc(0.7em + 0.1875rem);
    }
  }
  textarea {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const Footer = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  width: 250px;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background: #4a90e2;
  color: #fff;
  &:hover {
    background: #2275d7;
  }
`;

const validationSchema = Yup.object().shape({
  ticket: Yup.string().nullable(),
  title: Yup.string().required('Campo obrigatório'),
  email: Yup.string()
    .matches(/\S+@\w+\.\w+/, 'Informe um email válido')
    .required('Campo obrigatório'),
  message: Yup.string().required('Campo obrigatório'),
});

const ComplainHere = () => {
  const [loading, setLoading] = useState(false);

  const {
    showAlert,
    dismissAlert,
    Alert,
    state: { color: alertColor },
  } = useAlert();

  const showSubmit = useMemo(() => alertColor !== ALERT_TYPES.success, [
    alertColor,
  ]);

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    dismissAlert();
    try {
      await complainHere(values);
      resetForm();
      showAlert({
        color: ALERT_TYPES.success,
        message: 'Sua reclamação foi enviada com sucesso.',
      });
    } catch (e) {
      showAlert({ color: ALERT_TYPES.error, message: e.message });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Article>
      <SEO
        title="Central do Frete - Reclame Aqui"
        description="Prezamos pelo melhor atendimento, porém sabemos que mesmo assim, algo pode passar e você se sentir “perdido”, por isso, criamos um canal para que possa reportar o atendimento que não correspondeu."
      />
      <Header>
        <ReclameAqui>
          <span>Reclame Aqui</span>
        </ReclameAqui>
        <CentralDoFrete>Central do Frete</CentralDoFrete>
      </Header>
      <P>
        Nosso time cuida de tudo e é orientado a nunca deixar lhe faltar nada.
        Prezamos pelo melhor atendimento, a melhor informação, a rapidez e
        agilidade, porém sabemos que mesmo assim, algo pode passar e você se
        sentir “perdido”, por isso, criamos um canal para que possa reportar o
        atendimento que não correspondeu ou não está correspondendo as suas
        expectativas.
      </P>
      <Subtitle>
        Detalhe o máximo possível o seu problema com a Central do Frete:
      </Subtitle>
      <Formik
        initialValues={{
          ticket: '',
          email: '',
          title: '',
          message: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={() => (
          <Form>
            <FormWrapper>
              <TextField
                name="ticket"
                label="Qual o ticket de atendimento? (opcional)"
                placeholder="Ex: 123412"
              />
              <EmailField
                name="email"
                label="Qual o seu e-mail?"
                placeholder="seu@email.com"
              />
              <TextField
                name="title"
                maxLength={65}
                label="Título da sua reclamação"
                placeholder="Digite o título da sua reclamação"
              />
              <TextField
                type="textarea"
                name="message"
                label="Conte sua história"
                rows="4"
                placeholder="Escreva aqui todos os detalhes da sua história..."
              />
            </FormWrapper>
            <Alert />
            {showSubmit && (
              <Footer>
                <Button type="submit" disabled={loading}>
                  {loading && (
                    <Spinner
                      size="sm"
                      color="light"
                      style={{ marginRight: '8px' }}
                    />
                  )}
                  enviar
                </Button>
              </Footer>
            )}
          </Form>
        )}
      />
    </Article>
  );
};

export default ComplainHere;
